import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";

export default function PrivateHirePage({ location }) {
  const title = "Birthday | What's The Motive";
  const description =
    "Expect the unexpected with highly unique concepts, world-class DJ's, super fun props, free food, giveaways and your favourite anthems in the party capital, this is the motive!";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />

      <section className="birthday__section">
        <StaticImage src="../images/heroBirthday.png" alt="" />
      </section>
      <section className="birthday__section">
        <ul className="birthday__list">
          <li>
            <StaticImage src="../images/birthday0.png" alt="" />
            <article>
              <h2>Birthday Package</h2>
              <ul>
                <li>Birthday Cake with Sparklers</li>
                <li>VIP Table</li>
                <li>Sweets & Treats</li>
                <li>DJ Shout Out</li>
                <li>Entry for up to 10</li>
                <li>Cost £150</li>
              </ul>
              <a href="mailto:info@whatsthemotiveuk.com">Book now</a>
            </article>
          </li>
          <li>
            <StaticImage src="../images/birthday1.png" alt="" />
            <article>
              <h2>VIP Package</h2>
              <ul>
                <li>VIP Table</li>
                <li>Birthday Cake with Sparklers</li>
                <li>1 Spirit Bottle</li>
                <li>1 Prosecco Bottle</li>
                <li>Sweets & Treats</li>
                <li>DJ Shout Out</li>
                <li>Entry for up to 10</li>
                <li>Total: £350</li>
              </ul>
              <a href="mailto:info@whatsthemotiveuk.com">Book now</a>
            </article>
          </li>
          <li>
            <StaticImage src="../images/birthday2.png" alt="" />
            <article>
              <h2>All Inclusive Package</h2>
              <ul>
                <li>VIP Table</li>
                <li>2 Spirit Bottles</li>
                <li>2 Prosecco Bottles</li>
                <li>Sweets & Treats</li>
                <li>DJ Shout Out</li>
                <li>Personalised Photography</li>
                <li>Entry for up to 10</li>
                <li>Total: £650</li>
              </ul>
              <a href="mailto:info@whatsthemotiveuk.com">Book now</a>
            </article>
          </li>
        </ul>
      </section>
    </>
  );
}
